import React, {useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {Auth} from "aws-amplify";
import awsConfig from "./CognitoConfig";
import {UserDetails} from "./UserDetails";
import {CircularProgress} from "@material-ui/core";

function Users(props) {

    // Set up React hooks
    const [usersResponse, setUsers] = React.useState({
        waiting :  true,
        status: undefined,
        users: []
    })

    const  [userId, setUserId] = React.useState(null)

    useEffect( () => {
            setUsers({
                waiting: true,
            })
            Auth.currentSession()
                .then(data => {
                    const authorizations = {ApiKeyAuth: awsConfig.cognito.ApiKey,
                        AwsCognitoUserPool: data.idToken.jwtToken};
                    props.swaggerClient.then(client => {
                        client.apis.users.get_users({}, {securities: {authorized: authorizations}})
                            .then(response => {
                                setUsers({
                                    waiting: false,
                                    status: response.status,
                                    users: response.body
                                })
                            })
                            .catch((err) => {
                                setUsers({
                                    waiting: false,
                                    status: err.status,
                                })
                            })
                    });
                })
                .catch(err => console.log(err));
        },
        [props.swaggerClient]
    )

    // Do rendering

    if (usersResponse.waiting) {
        return (
            <div>
                <CircularProgress />
            </div>
        );

    }else if ( usersResponse.status === 200) {

        const onSelectionModelChange = (mode) => {
            setUserId(mode[0])
        }

        const columns = [
            { field: 'id', headerName: 'ID', width: 320 },
            {
                field: 'account_status',
                headerName: 'Status',
                width: 150,
                editable: false,
            },
            {
                field: 'enabled',
                headerName: 'Enabled',
                type: 'boolean',
                width: 220,
                editable: false,
            },
            {
                field: 'email',
                headerName: 'e-mail',
                width: 300,
                editable: false,
            },
        ];

        return (
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={usersResponse.users}
                    columns={columns}
                    pageSize={5}
                    onSelectionModelChange={onSelectionModelChange}
                />
                {userId && <UserDetails userId={userId} swaggerClient={props.swaggerClient}/> }
            </div>
        );

    }else{
        return (
            <div>
                'Failed to fetch users list'
            </div>
        );
    }

}

export default Users;
