import React from 'react';
import Typography from "@material-ui/core/Typography";

function Devices(props) {
    return (
        <div>
            <Typography paragraph> Devices </Typography>
        </div>
    );
}

export default Devices;
