import './App.css';
import Amplify from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import awsConfig from './CognitoConfig'
import SwaggerClient from 'swagger-client'
import MainDrawer from "./MainDrawer";

Amplify.configure(
    awsConfig.cognito
);

function App() {
    const specUrl =  process.env.PUBLIC_URL + '/illyrio-openapi.yaml';
    const swaggerClient = new SwaggerClient(specUrl);
    swaggerClient.then(client => {
        console.log("OpenAPi spec:");
        console.log(client.apis)
    });

    return (
        <div className="App">
          <AmplifySignOut />
            <MainDrawer swaggerClient={swaggerClient} />
        </div>
  );
}

export default withAuthenticator(App);
