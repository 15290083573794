import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import {Auth} from "aws-amplify";
import awsConfig from "./CognitoConfig";
import {
    CircularProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export function UserDetails(props) {
    const classes = useStyles();

    // Set up React hooks
    const [devicesResponse, setDevices] = React.useState({
        waiting :  true,
        status: undefined,
        devices: []
    })

    useEffect( () => {
            setDevices({
                waiting: true,
            })
            Auth.currentSession()
                .then(data => {
                    const authorizations = {ApiKeyAuth: awsConfig.cognito.ApiKey,
                        AwsCognitoUserPool: data.idToken.jwtToken};
                    props.swaggerClient.then(client => {
                        client.apis.users.get_user__user_uuid__devices({user_uuid: props.userId}, {securities: {authorized: authorizations}})
                            .then(response => {
                                setDevices({
                                    waiting: false,
                                    status: response.status,
                                    devices: response.body
                                })
                            })
                            .catch((err) => {
                                setDevices({
                                    waiting: false,
                                    status: err.status,
                                })
                            })
                    });
                })
                .catch(err => console.log(err));
        },
        [props.swaggerClient, props.userId]
    )

    // Do rendering
    if (devicesResponse.waiting) {
        return (
            <div>
                <CircularProgress />
            </div>
        );

    } else if ( devicesResponse.status === 200) {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>UUID</TableCell>
                                <TableCell align="right">Clock&nbsp;[ppb]</TableCell>
                                <TableCell align="right">Last used</TableCell>
                                <TableCell align="right">Seconds used</TableCell>
                                <TableCell align="right">Subscription id</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devicesResponse.devices.map((device) => (
                                <TableRow key={device.uuid}>
                                    <TableCell component="th" scope="row">
                                        {device.uuid}
                                    </TableCell>
                                    <TableCell align="right">{device.clock_offset_ppb}</TableCell>
                                    <TableCell align="right"><Moment unix>{device.billing.last_used_at}</Moment></TableCell>
                                    <TableCell align="right">{device.billing.sec_used}</TableCell>
                                    <TableCell align="right">
                                        <Link
                                            href={"https://dashboard.stripe.com/test/subscriptions/" + device.billing.subscription_id}
                                            target="_blank" rel="noopener noreferrer">
                                            {device.billing.subscription_id}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }else{
        return (
            <div>
                'Failed to fetch devices list'
            </div>
        );
    }
}
